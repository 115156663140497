<template>
  <div class="content md" v-if="buddhism">
    <a-result status="success" title="登记成功！">
      <template #extra>
        <a-button key="back" type="primary" @click="goRegister">继续登记</a-button>
        <a-button key="detail" @click="goDetail">查看详情</a-button>
      </template>
    </a-result>
  </div>
  <div class="content md flex-box vertical" v-else>
    <div class="buddha-temp-loading" v-if="loading">
      <a-spin />
    </div>
    <template v-else>
      <h3 class="buddha-form-title">
        <a-icon type="left" class="buddha-select-back" @click="goRegister"/>
        {{temp ? `${temp.name}登记` : '选择佛事/法会登记'}}
        <div class="buddha-history"><span>历史数据</span><a-switch v-model="history"/></div>
      </h3>
      <div class="buddha-select-tip" v-if="temp?.status === 0">{{temp?.name}}未启用，请联系管理员</div>
      <div class="buddha-select-tip" v-else-if="temp?.is_local != 1">{{temp?.name}}尚未开启线下登记，请联系管理员开启</div>
      <buddhism-form class="flex-grow buddha-add-area" :history="history" :buddha="temp" @change="handleChange" @update="handleUpdate" v-else-if="temp"></buddhism-form>
    </template>
  </div>
</template>

<script lang="tsx">
    import buddhismForm from "../../components/buddha/buddhism-form";

    export default {
        name: "BuddhismRegister",
        components: {
            buddhismForm
        },
        data() {
            return {
                buddhism: null,
                temp: null,
                history: false,
                loading: false,
            }
        },
        computed: {
            id() {
                return this.$route.params.id
            }
        },
        created() {
            this.getTemp();
        },
        methods: {
            handleChange(temp) {
                this.info = temp;
            },
            handleUpdate(buddhism) {
                this.buddhism = buddhism;
            },
            getTemp() {
                const temp = this.$store.getters. buddhaTemp;
                if (temp && temp.id == this.id) {
                    this.temp = temp;
                } else {
                    this.loading = true;
                    this.$axios(`/admin/fs-temp/${this.id}`).then(res => {
                        this.temp = res;
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            },
            goRegister() {
                this.$router.go(-1);
            },
            goDetail() {
                this.$router.replace({name: 'BuddhismDetail', params: {id: this.buddhism.id}})
            }
        }
    }
</script>

<style scoped lang="less">
  .buddha-form-title {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: var(--border);
    font-size: 18px;
    text-align: center;
  }
  .buddha-select-back {
    position: absolute;
    top: 4px;
    left: 10px;
  }
  .buddha-history {
    display: flex;
    align-items: center;
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 14px;
    span {
      margin-right: 8px;
    }
  }

  .buddha-select-tip {
    margin-top: 24px;
    color: @text-color-secondary;
    text-align: center;
    font-size: 16px;
  }

  .buddha-add-area {
    min-height: 0;
  }
</style>
